import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {getCommitteePositionLabel} from '../../Utils/SelectOptionLabelUtil';

export default function CommitteeDetailPage(props) {
  const [user] = useOutlet('user');
  const params = qs.parse(props.location.search);
  const [committees, setCommittees] = React.useState([]);
  const [selectedCommittee, setSelectedCommittee] = React.useState(null);
  const name = decodeURIComponent(params.name || '');

  const [relatedProducts, setRelatedProducts] = React.useState(null);
  const [relatedMeetings, setRelatedMeetings] = React.useState(null);
  const [relatedUserProfiles, setRelatedUserProfiles] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const resp = await JStorage.fetchAllDocuments(
          'Committee',
          {
            NAME: name,
          },
          ['-id'],
        );

        const committees = resp
          .sort((a, b) => {
            if (a.GROUP_CURRENT > b.GROUP_CURRENT) {
              return -1;
            } else if (a.GROUP_CURRENT < b.GROUP_CURRENT) {
              return 1;
            }
            return 0;
          })
          .filter((c) => c.IS_SHOW !== 'N')
          .filter((c) => c.GROUP_CURRENT.indexOf('30') === 0);

        setCommittees(committees);

        if (committees.length > 0) {
          setSelectedCommittee(committees[0]);
        }
      } catch (ex) {
        console.warn('CommitteeDetailPage ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [name]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const userProfiles = await JStorage.fetchAllDocuments(
          'user_profile',
          {
            owner: {$in: selectedCommittee.members.map((c) => c.owner)},
          },
          ['-created'],
        );
        const products = await JStorage.fetchAllDocuments(
          'product',
          {
            committee: selectedCommittee.id,
            archived: {$ne: true},
          },
          ['-created'],
        );
        const meetings = await JStorage.fetchAllDocuments(
          'Meeting',
          {
            committee: selectedCommittee.id,
          },
          ['-created'],
        );

        setRelatedUserProfiles(userProfiles);
        setRelatedProducts(products);
        setRelatedMeetings(meetings);
      } catch (ex) {
        console.warn('CommitteeDetailPage ex', ex);
      }
      AppActions.setLoading(false);
    }

    if (selectedCommittee) {
      fetchData();
    }
  }, [selectedCommittee]);

  if (!user) {
    return (
      <Wrapper>
        <main>
          <h1>您沒有瀏覽權限</h1>
        </main>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <main>
        <h1>{name}</h1>
        <div className="main-cat-tabs">
          {committees.slice(0, 3).map((c) => {
            const selected = c.id === selectedCommittee?.id;
            return (
              <Button
                key={c.id}
                type={selected ? 'primary' : 'link'}
                size="large"
                onClick={() => setSelectedCommittee(c)}>
                {c.GROUP_CURRENT || c.NAME}
              </Button>
            );
          })}
        </div>

        {committees.length === 0 && (
          <h2 style={{textAlign: 'center'}}>敬請期待</h2>
        )}

        {selectedCommittee && (
          <div className="main-cat-content">
            <div className="description">{selectedCommittee.DESCRIPTION}</div>
            {relatedProducts &&
              Array.isArray(relatedProducts) &&
              relatedProducts.length > 0 && (
                <div className="container">
                  <div className="title-container">
                    <div className="prefix" />
                    <h2 className="title">活動</h2>
                  </div>
                  <div className="items-container">
                    {relatedProducts.map((p) => (
                      <div className="item">
                        <div className="fake" />
                        <span>
                          <span className="date">
                            {p.session?.date || '線上自由安排'}
                          </span>
                          <Button
                            type="link"
                            size="large"
                            className="name"
                            onClick={() =>
                              AppActions.navigate(`/product?id=${p.id}`)
                            }>
                            {p.name}
                          </Button>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {relatedMeetings &&
              Array.isArray(relatedMeetings) &&
              relatedMeetings.length > 0 && (
                <div className="container">
                  <div className="title-container">
                    <div className="prefix" />
                    <h2 className="title">會議</h2>
                  </div>
                  <div className="items-container">
                    {relatedMeetings.map((m) => (
                      <div className="item">
                        <div className="fake" />
                        <span>
                          <span className="date">{m.EFF_DATE}</span>
                          <span className="name">{m.MTNG_NAME}</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {Array.isArray(selectedCommittee.members) &&
              selectedCommittee.members.length > 0 && (
                <div className="container">
                  <div className="title-container">
                    <div className="prefix" />
                    <h2 className="title">成員</h2>
                  </div>
                  <div className="items-container">
                    {selectedCommittee.members
                      .filter(
                        (m) => m.POSITION === 'B01' || m.POSITION === 'B03',
                      )
                      .sort(
                        (a, b) => a.POSITION.slice(-1) - b.POSITION.slice(-2),
                      )
                      .map((m) => {
                        const profile = relatedUserProfiles.find(
                          (u) => u.owner === m.owner,
                        );
                        return (
                          <div className="item">
                            <div className="fake" />
                            <span>
                              <span className="title">
                                {getCommitteePositionLabel(m.POSITION)}
                              </span>
                              <span className="name">
                                {profile?.name || '---'}
                              </span>
                            </span>
                          </div>
                        );
                      })}

                    {selectedCommittee.members
                      .filter((m) => m.POSITION === 'B02')
                      .map((m) => {
                        const profile = relatedUserProfiles.find(
                          (u) => u.owner === m.owner,
                        );
                        return (
                          <div className="item">
                            <div className="fake" />
                            <span>
                              <span className="title">
                                {getCommitteePositionLabel(m.POSITION)}
                              </span>
                              <span className="name">
                                {profile?.name || '---'}
                              </span>
                            </span>
                          </div>
                        );
                      })}

                    {selectedCommittee.members
                      .filter((m) => m.POSITION === 'Z01')
                      .map((m) => {
                        const profile = relatedUserProfiles.find(
                          (u) => u.owner === m.owner,
                        );
                        return (
                          <div className="item">
                            <div className="fake" />
                            <span>
                              <span className="title">
                                {getCommitteePositionLabel(m.POSITION)}
                              </span>
                              <span className="name">
                                {profile?.name || '---'}
                              </span>
                            </span>
                          </div>
                        );
                      })}

                    {selectedCommittee.members_normal
                      ?.filter((m) => m.POSITION === 'Z02')
                      .map((m) => {
                        return (
                          <div className="item">
                            <div className="fake" />
                            <span>
                              <span className="title">
                                {getCommitteePositionLabel(m.POSITION)}
                              </span>
                              <span className="name">{m.name}</span>
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
          </div>
        )}
      </main>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  & > main {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px;

    & > h1 {
      margin-bottom: 20px;
      text-align: center;
    }

    & .main-cat-tabs {
      display: flex;
      border-bottom: 1px solid #aaa;
      margin-bottom: 20px;

      & > * {
        flex: 1;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 1.15rem;
      }
    }

    & .main-cat-content {
      padding: 10px;

      & .description {
        margin-bottom: 20px;
        font-size: 1.15rem;
        line-height: 2.5rem;
        white-space: pre-wrap;
      }

      & > .container {
        margin-bottom: 20px;

        & > .title-container {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          & > .prefix {
            width: 5px;
            height: 25px;
            background-color: var(--primaryColor);
          }

          & > .title {
            padding-left: 5px;
          }
        }

        & > .items-container {
          & .item {
            padding: 10px 10px 5px 10px;
            font-size: 1.25rem;
            width: 100%;
            text-align: center;

            display: flex;
            align-items: center;
            flex-wrap: wrap;

            & > span > .title,
            .date {
              margin-right: 20px;
              font-weight: 500;
            }

            & > span > .name {
              font-size: 1.25rem;
            }
          }

          & .fake {
            width: 100px;
            height: 0px;
          }
        }
      }
    }
  }
`;
